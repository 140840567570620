<template>
  <EmTooltip v-if="title" :disabled="disableTooltip" :force-tooltip="forceTooltip" v-bind="tooltipAttrs">
    <template #activator="{ props:slotProps }">
      <v-btn
        v-bind="{...$attrs,...slotProps }"
        ref="button"
        class="em-icon-button"
        :class="{'squared': squared, 'events-none': eventsNone, [buttonClass]: true}"
        :ripple="!display.mobile"
        :data-cy="dataCy"
        icon
        variant="text"
        :size="size"
      >
        <v-badge
          :model-value="hasAlert"
          :color="alertColor"
          overlap
          dot
          bordered
        >
          <EmIcon :size="size" :color="color" :icon="icon" />
        </v-badge>
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </EmTooltip>
  <v-btn
    v-else
    v-bind="{...$attrs,...props}"
    ref="button"
    class="em-icon-button"
    :class="{'squared': squared, 'events-none': eventsNone, [buttonClass]: true}"
    :ripple="!display.mobile"
    :data-cy="dataCy"
    icon
    variant="text"
    :size="size"
  >
    <v-badge
      :model-value="hasAlert"
      :color="alertColor"
      overlap
      dot
      bordered
    >
      <EmIcon :size="size" :color="color" :icon="icon" />
    </v-badge>
  </v-btn>
</template>
<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
const props = defineProps({
  title: { type: String, default: '' },
  icon: { type: String, required: true },
  color: { type: String, default: 'neutral-lighten-2' },
  hasAlert: { type: Boolean, default: false },
  disableTooltip: { type: Boolean, default: false },
  squared: { type: Boolean, default: false },
  size: { type: String, default: 'default' },
  buttonClass: { type: String, default: '' },
  alertColor: { type: String, default: 'error' },
  forceTooltip: { type: Boolean, default: false },
  eventsNone: { type: Boolean, default: false },
  tooltipProps: { type: Object, default: () => ({}) }
})
const $attrs = useAttrs()
const tooltipAttrs = computed(() => {
  const found = ['top', 'bottom', 'left', 'right'].find(position => position in $attrs) || 'bottom'
  return { [found]: true, ...props.tooltipProps }
})
const dataCy = computed(() => $attrs['data-cy-form-field'])
const button = ref()
const getButton = () => button.value
defineExpose({ tooltipAttrs, dataCy, getButton })
</script>
<style lang="scss" scoped>
.em-icon-button {
  &.squared {
    border-radius: 10px !important;
  }
  &.events-none {
    pointer-events: none !important;
  }
}
.em-icon-button.v-btn--density-default {
  width: var(--v-btn-height) !important;
  height: var(--v-btn-height) !important;
}
:deep(.v-btn__loader) > .v-progress-circular.v-progress-circular--indeterminate {
  color: rgb(var(--v-theme-primary)) !important;
  width: 32px !important;
}
</style>
